// Here you can add other styles
@font-face {
  font-family: "NanumPenScript";
  src: local("NanumPenScript"),
    url("../assets/fonts/NanumPenScript-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "HeartWarming";
  src: local("HeartWarming"), url("../assets/fonts/Heart-Warming.otf") format("truetype");
  font-weight: normal;
}

.table-class td {
  height: 180px !important;
}
